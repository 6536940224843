.pageTiles {
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;

  &.useSlider {
    display: block;
    .tile {
      max-width: 100%;
    }
  }
}

.pageTile__content {

  &:hover,
  &:focus {
    text-decoration: none;
    // .pageTile_details:before {
    //   background: $primary-color;
    // }
    .pageTile_details {
      background: $color-yellow;
    }

    .pageTile__title{
      color: white;
    }
    
    .pageTile_desc {
      color: white;
    }
    
    .tile__bg{
      transform: scale(1.02);
    }

  }
}

.pageTile__date{
  font-family: $family-header;
  font-size: 0.8rem;
  text-transform: uppercase;
  //font-weight: bold;
  line-height: 1;
  margin: 0.5rem 0;
  color: black;
}

.pageTile__title {
  font-family: $family-header;
  font-size: 1.5rem;
  text-transform: uppercase;
  //font-weight: bold;
  line-height: 1;
  margin: 0.5rem 0;
  color: white;
}

div.pageTile.tile.blog .pageTile__title{
  color: $primary-color;
}

.pageTile__line{
  border-color: white;
  width: 70%;
}

.pageTile_desc {
  line-height: 1.3;
  font-size: 0.9rem;
  color: white;
  margin-bottom: 0.4rem;
}

.pageTile_details {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  padding: 0.5rem 1rem;
  text-align: center;
  background-color: $color-dark-blue;
  //background-color: $primary-color;
}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(to bottom left, rgba(#fff, 0.3), rgba(#fff, 0));
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}
